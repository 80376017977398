const uniqBy = require('lodash/uniqBy')
const keyBy = require('lodash/keyBy')

export function handleQueryResolve(results, err) {
    if (!!results && results.recordset) {
        return results.recordset
    }
    if (err) {
        console.error(err)
    }

    // return empty array if no results
    return []
}

export function buildMapToField(objToMap) {
    const mapFields = getBasicMap() // Basic field mappers
    if (!objToMap) {
        throw new Error('${objToMap} is undefined')
    }

    return Object.keys(objToMap).reduce((cur, queryField) => {
        try {
            const key = mapFields[queryField]
            const val = objToMap[queryField]
            if (!key) {
                return cur

                // FIXME  We need to map values into one single interface
                //        Under getBasicMap() for different integrations
                // return {
                //   ...cur,
                //   [queryField]: val
                // };
            }

            if (typeof key === 'function') {
                return {
                    ...cur,
                    ...key(val),
                }
            }

            // if its straight up
            return {
                ...cur,
                [key]: val,
            }
        } catch (err) {
            console.error(err, ` >> queryField: ${queryField}`, { mapFields }, { objToMap })
        }
    }, {})
}

export function getBasicMap() {
    return {
        FirstName: 'FIRST NAME',
        LastName: 'LAST NAME',
        FamilyDoc: familyDocName => {
            // when they don't exist
            if (!familyDocName) {
                return {
                    'PRIMARY LAST NAME': '',
                    'PRIMARY FIRST NAME': '',
                }
            }

            const brokenName = familyDocName.split(',')

            // FIXME should extract from base
            return {
                'PRIMARY LAST NAME': brokenName[0].trim(),
                'PRIMARY FIRST NAME': brokenName[1].trim(),
            }
        },
        OHIP: 'HEALTH CARD NUMBER',
        Address: mapFunctions.patientAddress,
        City: 'CITY\/TOWN',
        Province: mapFunctions.defaultProvince,
        Postal: 'POSTAL CODE',
        EMail: 'EMAIL ADDRESS',
        HomePhone: 'TELEPHONE NUMBER',
        MobilePhone: 'CELLPHONE NUMBER',
        Allergies: 'LIST OF ALLERGIES',
        DOB: 'DATE OF BIRTH',
        Gender: 'GENDER',
        Medication: 'Medication',
        PatientID: 'PatientID',
        DoctorID: 'DoctorID',
        DIN: 'DIN',
        Days: 'Days',

        GenericName: 'GenericName',
        BrandName: 'BrandName',
        RxNumber: 'RxNumber',
        RxID: 'RxID',
        RxDate: 'RxDate',
        RxStrength: 'RxStrength',
        RxQtyDispense: 'RxQtyDispense',
        SIGFull: 'SIG',
        RxDays: 'RxDays',
        RxInterval: 'RxInterval',

        InsuranceProvider: 'InsuranceProvider',

        PharmacyAddress: mapFunctions.pharmacyAddress,
        PharmacyCity: 'PHARMACY CITY',
        PharmacyFax: 'PHARMACY FAX NUMBER',
        PharmacyName: 'PHARMACY NAME',
        PharmacyPhone: 'PHARMACY TELEPHONE NUMBER',
        PharmacyPostal: 'PHARMACY POSTAL CODE',
        PharmacyProvince: 'PHARMACY PROVINCE',

        DoctorFirstName: 'PRIMARY FIRST NAME',
        DoctorLastName: 'PRIMARY LAST NAME',
        DoctorEmail: 'PRIMARY EMAIL ADDRESS',
        DoctorPhone: 'PRIMARY PHONE #',
        DoctorFax: 'PRIMARY FAX #',
        DoctorAddress: mapFunctions.primaryAddress,
        DoctorCity: 'PRIMARY CITY',
        DoctorProvince: 'PRIMARY POSTAL CODE',
        DoctorPostal: 'PRIMARY PROVINCE',
        CPSO: 'CPSO',

        // '': 'PRIMARY EMAIL ADDRESS',
        PlanCode: 'PlanCode',
        CarrierID: 'CarrierID',
        PlanID: 'PlanID',
        GroupID: 'GroupID',
        PlanOrder: 'PlanOrder',
        Relationship: 'Relationship',
        PatientCode: 'PatientCode',
        Brk: 'Brk',
        Noon: 'Noon',
        Supp: 'Supp',
        Bed: 'Bed',
        UnitDose: 'UnitDose',
        filldateedit: 'filldateedit',

        noon: 'Noon',
        sup: 'Supp',
        bed: 'Bed',
        brk: 'Brk',
        Strength: 'Strength',
        'rx.ID': 'rx.ID',
        SalesYear: 'SalesYear',
        SalesMonth: 'SalesMonth',
        MixID: 'MixID',
        'rx.DocID': 'rx.DocID',
        'DrgMix.Description': 'DrgMix.Description',
        OrigRxNum: 'OrigRxNum',
        RxNum: 'RxNum',
        FillDate: 'FillDate',
        DispQty: 'DispQty',
        AAC: 'AAC',
        Cost: 'Cost',
        Markup: 'Markup',
        Fee: 'Fee',
        MixTime: 'MixTime',
        MixFee: 'MixFee',
        Status: 'Status',
        DocAddressLoc: 'DocAddressLoc',
        SSCFee: 'SSCFee',
        PriceDiscount: 'PriceDiscount',
        DeductDiscount: 'DeductDiscount',
        DiscountCost: 'DiscountCost',
        DiscountMarkup: 'DiscountMarkup',
        DiscountFee: 'DiscountFee',
        DiscountMixFee: 'DiscountMixFee',
        DiscountSSCFee: 'DiscountSSCFee',
        time_added: 'time_added',
        ID: 'ID',
        DrgID: 'DrgID',
        DrgPackID: 'DrgPackID',
        PackSize: 'PackSize',
        Qty: 'Qty',
        PerUnits: 'PerUnits',
        ActiveIngredient: 'ActiveIngredient',
        InventoryUsed: 'InventoryUsed',
        LotNum: 'LotNum',
        ExpiryDate: 'ExpiryDate',
        DrgPackTierId: 'DrgPackTierId',
        AcqCost: 'AcqCost',
        IsSupply: 'IsSupply',
        Seq: 'Seq',
        PatPlnID: 'PatPlnID',
        Pays: 'Pays',
        TranType: 'TranType',
        AdjState: 'AdjState',
        SubPlanCode: 'SubPlanCode',
        IsRT: 'IsRT',
        AdjDate: 'AdjDate',
        SSC: 'SSC',
        SANum: 'SANum',
        Interventions: 'Interventions',
        ReasonCodeRef: 'ReasonCodeRef',
        ReasonCode: 'ReasonCode',
        StreamData: 'StreamData',
        ClaimType: 'ClaimType',
        AdjSendDate: 'AdjSendDate',
        AdjustmentStatus: 'AdjustmentStatus',
        PseudoDIN: 'PseudoDIN',
        AdjudicationLevel: 'AdjudicationLevel',
        PaymentSeq: 'PaymentSeq',
        NonDUESeq: 'NonDUESeq',
        PseudoDinType: 'PseudoDinType',
        ClaimTypeOverride: 'ClaimTypeOverride',
        DrgMixFeeMastId: 'DrgMixFeeMastId',
        IsClinicalPlan: 'IsClinicalPlan',
        CancelsRxPlnId: 'CancelsRxPlnId',
        ModifyInProgress: 'ModifyInProgress',
        CopayStratMastId: 'CopayStratMastId',
        CutbackDiscountCost: 'CutbackDiscountCost',
        CutbackDiscountMarkup: 'CutbackDiscountMarkup',
        CutbackDiscountFee: 'CutbackDiscountFee',
        CutbackDiscountMixFee: 'CutbackDiscountMixFee',
        CutbackDiscountSSCFee: 'CutbackDiscountSSCFee',
        CopayDiscountCost: 'CopayDiscountCost',
        CopayDiscountMarkup: 'CopayDiscountMarkup',
        CopayDiscountFee: 'CopayDiscountFee',
        CopayDiscountMixFee: 'CopayDiscountMixFee',
        CopayDiscountSSCFee: 'CopayDiscountSSCFee',
        AdjudicationAdjustedBits: 'AdjudicationAdjustedBits',
        SSCOverride: 'SSCOverride',
        InterventionsOverride: 'InterventionsOverride',
        PatID: 'PatID',
        DocID: 'DocID',
        Init: 'Init',
        CancelDate: 'CancelDate',
        FirstFillDate: 'FirstFillDate',
        LastFillDate: 'LastFillDate',
        NextFillQty: 'NextFillQty',
        AuthQty: 'AuthQty',
        RemQty: 'RemQty',
        DaysSupply: 'DaysSupply',
        Labels: 'Labels',
        ProductSelection: 'ProductSelection',
        OralWritten: 'OralWritten',
        SIG: 'SIG',
        SigCRC: 'SigCRC',

        ManualPrice: 'ManualPrice',
        TrialRx: 'TrialRx',
        PartialFill: 'PartialFill',
        DrugExpiryDate: 'DrugExpiryDate',
        StopDate: 'StopDate',
        RxExpiryDate: 'RxExpiryDate',
        FollowUpDate: 'FollowUpDate',
        Lot: 'Lot',
        SplitQty: 'SplitQty',
        SplitEvenly: 'SplitEvenly',
        LabelQtySplit: 'LabelQtySplit',
        Inactive: 'Inactive',
        CopiedTo: 'CopiedTo',
        CopiedFrom: 'CopiedFrom',
        TherapeuticStartDate: 'TherapeuticStartDate',
        TransferredFromDate: 'TransferredFromDate',
        TransferredToDate: 'TransferredToDate',
        ScriptImage: 'ScriptImage',
        UserInit: 'UserInit',
        UnitDoseStartDate: 'UnitDoseStartDate',
        WrittenDate: 'WrittenDate',
        MinIntervalDays: 'MinIntervalDays',
        BackDatedOn: 'BackDatedOn',
        PrcStratID: 'PrcStratID',
        CorporatePriceID: 'CorporatePriceID',
        Charged: 'Charged',
        POSPending: 'POSPending',
        NHCycle: 'NHCycle',
        NHBatchFill: 'NHBatchFill',
        NHUnitDose: 'NHUnitDose',
        NHWardStock: 'NHWardStock',
        NHMedType: 'NHMedType',
        NHExtraMARSpace: 'NHExtraMARSpace',
        NHEODStartDate: 'NHEODStartDate',
        NHLabels: 'NHLabels',
        ScriptImageID: 'ScriptImageID',
        FirstDrugName: 'FirstDrugName',
        SecondDrugName: 'SecondDrugName',
        PrintSigSmall: 'PrintSigSmall',
        UnlimitedRefills: 'UnlimitedRefills',
        NHComment: 'NHComment',
        RoboticPending: 'RoboticPending',
        NHDaysInUnitDoseCycle: 'NHDaysInUnitDoseCycle',
        NHCardNum: 'NHCardNum',
        AutoRefill: 'AutoRefill',
        NHSplitQty: 'NHSplitQty',
        NHSplitEvenly: 'NHSplitEvenly',
        NHLabelQtySplit: 'NHLabelQtySplit',
        POSTrigger: 'POSTrigger',
        NHBatchUseBatchValues: 'NHBatchUseBatchValues',
        NHBatchDailyDosage: 'NHBatchDailyDosage',
        NHBatchRegLabels: 'NHBatchRegLabels',
        NHBatchNHLabels: 'NHBatchNHLabels',
        MethadoneIngestDate: 'MethadoneIngestDate',
        IsHidden: 'IsHidden',
        NarcRefNum: 'NarcRefNum',
        IsMistake: 'IsMistake',
        OrderReceived: 'OrderReceived',
        StoreID: 'StoreID',
        NHID: 'NHID',
        NHWardID: 'NHWardID',
        RxChangedOn: 'RxChangedOn',
        WorkOrderId: 'WorkOrderId',
        ForceReportable: 'ForceReportable',
        CeRxRxId: 'CeRxRxId',
        CeRxDispenseId: 'CeRxDispenseId',
        UnitDosePrcStratId: 'UnitDosePrcStratId',
        Merged: 'Merged',
        FeeForServiceType: 'FeeForServiceType',
        UserField1: 'UserField1',
        LastRxStatus: 'LastRxStatus',
        InactivatedOn: 'InactivatedOn',
        FDBDosageFormCode: 'FDBDosageFormCode',
        FDBRouteCode: 'FDBRouteCode',
        PickupNotificationRequested: 'PickupNotificationRequested',
        NHUnitDoseType: 'NHUnitDoseType',
        NHUnitDoseFreq: 'NHUnitDoseFreq',
        NHUnitDoseAnchorDate: 'NHUnitDoseAnchorDate',
        CeRxOrderType: 'CeRxOrderType',
        CopiedFromReason: 'CopiedFromReason',
        CopiedToReason: 'CopiedToReason',
        WasUndeliverable: 'WasUndeliverable',
        CancelRefillType: 'CancelRefillType',
        NHBatchType: 'NHBatchType',
        LegacyWorkflow: 'LegacyWorkflow',
        CounselingRequired: 'CounselingRequired',
        CounselingResponse: 'CounselingResponse',
        IdentificationRequiredOnDelivery: 'IdentificationRequiredOnDelivery',
        ScriptImagePosition: 'ScriptImagePosition',
        ScriptImagePage: 'ScriptImagePage',
        ChargeToAR: 'ChargeToAR',
        RxContextInfo: 'RxContextInfo',
        RxContextInfoCompressionType: 'RxContextInfoCompressionType',
        OrderCreatedFromNetwork: 'OrderCreatedFromNetwork',
        IsPharmacistPrescribe: 'IsPharmacistPrescribe',
        PasstimeCode: 'PasstimeCode',
        DrugSource: 'DrugSource',
        PrescriptiveAuthority: 'PrescriptiveAuthority',
        UserId: 'UserId',
        UserUserId: 'UserUserId',
        CounselingReason: 'CounselingReason',
        AdjLogInfo: 'AdjLogInfo',
        AdjLogInfoCompressionType: 'AdjLogInfoCompressionType',
        FillingAdjComplete: 'FillingAdjComplete',
        BaseCost: 'BaseCost',
        CouponValue: 'CouponValue',
        RxRefillSyncType: 'RxRefillSyncType',
        ImmunizationId: 'ImmunizationId',
        ImmunizationProductType: 'ImmunizationProductType',
        DoNotDispenseBeforeDate: 'DoNotDispenseBeforeDate',
        Adapted: 'Adapted',
        MagicNumber: 'MagicNumber',
        AdminSites: 'AdminSites',
        MaxDoseQty1: 'MaxDoseQty1',
        MaxDoseQtyUnit1: 'MaxDoseQtyUnit1',
        MaxDoseRange1: 'MaxDoseRange1',
        MaxDoseRangeUnit1: 'MaxDoseRangeUnit1',
        MaxDoseQty2: 'MaxDoseQty2',
        MaxDoseQtyUnit2: 'MaxDoseQtyUnit2',
        MaxDoseRange2: 'MaxDoseRange2',
        MaxDoseRangeUnit2: 'MaxDoseRangeUnit2',
        ManualStructuredDosing: 'ManualStructuredDosing',
        AdditionalStructuredSig: 'AdditionalStructuredSig',
        CarryNumber: 'CarryNumber',
        PharmacistPrescribeMedReviewRxId: 'PharmacistPrescribeMedReviewRxId',
        MaxDispQty: 'MaxDispQty',
        TreatmentType: 'TreatmentType',
        NoDocERenewal: 'NoDocERenewal',
        KrollCareRequested: 'KrollCareRequested',
        RefillReminderDate: 'RefillReminderDate',
        LegalAuthorityExpiryDate: 'LegalAuthorityExpiryDate',
        drugGroup: 'drugGroup',
        ParentRxNumber: 'ParentRxNumber',
        RXNumber: 'RXNumber',
        TotalAuthorized: 'TotalAuthorized',
        QtyDispense: 'QtyDispense',
        QtyRemain: 'QtyRemain',
        DrugCost: 'DrugCost',
        CostMarkUp: 'CostMarkUp',
        TotalCost: 'TotalCost',
        TotalPrice: 'TotalPrice',
        Discount: 'Discount',
        CustPrice: 'CustPrice',
        TotalThirdParty: 'TotalThirdParty',
        Copay: 'Copay',
        TS: 'TS',
        RxPlnID: 'RxPlnID',
        ResultCode: 'ResultCode',
        InterventionCodes: 'InterventionCodes',
        TraceNum: 'TraceNum',
        RefNum: 'RefNum',
        ErrorCodes: 'ErrorCodes',
        Messages: 'Messages',
        PlanPays: 'PlanPays',
        Request: 'Request',
        Response: 'Response',
        SubCost: 'SubCost',
        SubMarkup: 'SubMarkup',
        SubFee: 'SubFee',
        SubMixFee: 'SubMixFee',
        SubSSCFee: 'SubSSCFee',
        PrevPaid: 'PrevPaid',

        ParserType: 'ParserType',
        RequestCompressionType: 'RequestCompressionType',
        ResponseCompressionType: 'ResponseCompressionType',
        RequestData: 'RequestData',
        ResponseData: 'ResponseData',
        TransmissionStatus: 'TransmissionStatus',

        AdjResponseDate: 'AdjResponseDate',
        RequestEncoding: 'RequestEncoding',
        ResponseEncoding: 'ResponseEncoding',

        ClaimResult: 'ClaimResult',
        AdjRouteId: 'AdjRouteId',

        Deductible: 'Deductible',
        CoInsurance: 'CoInsurance',
        genericName: 'genericName',
        brandName: 'brandName',
        strength: 'strength',
        Mfr: 'Mfr',
        BirthYear: 'BirthYear',
        TotalCustomer: 'TotalCustomer',
        TotalDiscountedCustomer: 'TotalDiscountedCustomer',
        TotalCostDiscount: 'TotalCostDiscount',
        Description: 'Description',

        RelationShip: 'RelationShip',
        SmartCard: 'SmartCard',
        InactivatedOn: 'InactivatedOn',
    }
}

// functional mappings that extends
const mapFunctions = {
    defaultProvince: province => ({
        PROVINCE: province || 'ONTARIO',
    }),
    patientAddress: address => {
        if (address) {
            const breakdown = parseAddress(address)

            return {
                'UNIT NUMBER': breakdown.unitNumber,
                'STREET NUMBER': breakdown.streetNumber,
                'STREET NAME': breakdown.streetName,
            }
        }

        return {
            'UNIT NUMBER': ' ',
            'STREET NUMBER': ' ',
            'STREET NAME': ' ',
        }
    },
    pharmacyAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PHARMACY UNIT NUMBER': breakdown.unitNumber,
            'PHARMACY STREET NUMBER': breakdown.streetNumber,
            'PHARMACY STREET NAME': breakdown.streetName,
        }
    },
    primaryAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PRIMARY UNIT NUMBER': breakdown.unitNumber,
            'PRIMARY STREET NUMBER': breakdown.streetNumber,
            'PRIMARY STREET NAME': breakdown.streetName,
        }
    },
}

function parseAddress(addressStr) {
    let unitNumber = ''
    let streetNumber = ''
    let streetName = ''
    const streetBreakdown = addressStr.match(/[A-za-z]+[\s|A-za-z]+/g)
    if (streetBreakdown && streetBreakdown.length) {
        streetName = streetBreakdown[0].trim()
        streetNumber = addressStr.replace(streetName, '').trim()
        if (streetNumber.indexOf('-') != -1) {
            const numbersBreakdown = streetNumber.split('-')
            unitNumber = numbersBreakdown[0].trim()
            streetNumber = numbersBreakdown[1].trim()
        }
    }

    return {
        unitNumber,
        streetNumber,
        streetName,
    }
}

// Specific for Kroll
export function mapSigToSigFull(medicationResults) {
    return Promise.resolve(medicationResults).then(medicationResults => {
            const listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map(record => ({
                SIG: record.SIGFULL,
                GenericName: record.GenericName,
                RxNumber: record.RxNumber,
                RxDate: record.RxDate,
                DIN: record.DIN,
                Status: record.Status,
                RxStrength: record.RxStrength,
                BrandName: record.BrandName,
                RxQtyDispense: record.RxQtyDispense,
                QtyRemain: record.QtyRemain,
                TotalAuthorized: record.TotalAuthorized,

                SIGFull: record.SIGFULL,
                PatientID: record.PatientID,
                DocID: record.DocID,
                Days: record.Days,
                CPSO: record.CPSO,
                LastName: record.LastName,
                FirstName: record.FirstName,
                DoctorFax: record.DoctorFax,
                DoctorPhone: record.DoctorPhone,
                sixMonth: record.sixMonth,
                oneMonth: record.oneMonth,
                oneDay: record.oneDay,
            }))

            return {
                medications: uniqBy(listOfNonRepeatedMeds),
            }
        })
        .then(medicationsInst => this.query(`
              SELECT
                  [Token],
                  [Text]
              FROM Pharmacy.dbo.Sig
              WHERE Language = 'E'
          `)
            .then(handleQueryResolve)
            .then(tokens => {
                const tokenMap = keyBy(tokens, o => o.Token)
                const medications = medicationsInst.medications.map(medication => {
                    const sig = medication.SIG
                    if (!sig) {
                        return medication
                    }
                    let newSigDescriptionA
                    let newSigDescription
                    if (sig.indexOf(' ') > -1) {
                        newSigDescriptionA = sig.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
                        newSigDescription = newSigDescriptionA.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
                    } else {
                        newSigDescription = _getSigToken(sig, tokenMap)
                    }
                    medication.SIGFull = newSigDescription

                    return medication
                })

                // spread it
                return medications
            }))
}

// export function mapSigToSigFullLoop(medicationResults) {
//   return Promise.resolve(medicationResults).then(medicationResults => {
//     const listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map(record => ({
//       SIG: record.SIGFULL,
//       GenericName: record.GenericName,
//       RxNumber: record.RxNumber,
//       RxDate: record.RxDate,
//       DIN: record.DIN,
//       Status: record.Status,
//       RxStrength: record.RxStrength,
//       BrandName: record.BrandName,
//       RxQtyDispense: record.RxQtyDispense,
//       QtyRemain: record.QtyRemain,
//       TotalAuthorized: record.TotalAuthorized,

//       SIGFull: record.SIGFULL,
//       PatientID: record.PatientID,
//       DocID: record.DocID,
//       Days: record.Days,
//       CPSO: record.CPSO,
//       LastName: record.LastName,
//       FirstName: record.FirstName,
//       DoctorFax: record.DoctorFax,
//       DoctorPhone: record.DoctorPhone,
//       sixMonth: record.sixMonth,
//       oneMonth: record.oneMonth,
//       oneDay: record.oneDay,
//     }))

//     return {
//       medications: uniqBy(listOfNonRepeatedMeds),
//     }
//   })
//     .then(medicationsInst => this.query(`
//               SELECT
//                   [Token],
//                   [Text]
//               FROM Pharmacy.dbo.Sig
//               WHERE Language = 'E'
//           `)
//       .then(handleQueryResolve)
//       .then(tokens => {
//         const tokenMap = keyBy(tokens, o => o.Token)
//         const medications = medicationsInst.medications.map(medication => {
//           const sig = medication.SIG
//           if (!sig) {
//             return medication
//           }
//           let newSigDescriptionA
//           let newSigDescription
//           if (sig.indexOf(' ') > -1) {
//             newSigDescriptionA = sig.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
//             newSigDescription = newSigDescriptionA.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
//           } else {
//             newSigDescription = _getSigToken(sig, tokenMap)
//           }
//           medication.SIGFull = newSigDescription

//           return medication
//         })

//         // spread it
//         return medications
//       }))
// }

function _getSigToken(token, tokenMap) {
    const tokenInst = tokenMap[token]
    if (tokenInst) {
        return tokenInst.Text
    }

    return token
}