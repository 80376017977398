import moment from 'moment'
import { handleQueryResolve } from '../utils'

export default function (fromDate, toDate) {
  // console.log(fromDate + ' ' + toDate);

  const dateFormat = 'DD/MM/YYYY'
  const fromDateStr = moment(fromDate).format(dateFormat)
  const toDateStr = moment(toDate).format(dateFormat)

  // const fromDate = '03/12/2019';
  // const toDate = '04/12/2019';

  // Fixme: For some reason this doesn't seem to work and give date conversion error
  // Need to figure out how to make this work instead of inserting string value
  // directly into query
  const attributes = []
  const where = []
  if (fromDate) {
    attributes.push({
      param: 'fromDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191203\',112)',
    })

    attributes.push({
      param: 'toDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191204\',112)',
    })

    where.push('CONVERT(DATE,Rx.RxDate) BETWEEN @fromDate')
    where.push('@toDate')
  }

  const queryStr = `
  SELECT
  Pat.FirstName,
  Pat.LastName,
  Pat.FirstName + ' ' + Pat.LastName AS PatientName,
  MixID,
  Rx.PatID as PatientID,
  DrgMix.Description,
  RxNum,
  CONVERT(DATE,Rx.FillDate,112) AS FillDate
FROM
  pharmacy.dbo.Rx
  INNER JOIN pharmacy.dbo.Pat on rx.PatID = pat.ID
  INNER JOIN pharmacy.dbo.DrgMix ON DrgMix.ID = rx.MixID
WHERE
  MixID >= 1 
 -- AND Rx.FillDate > DATEADD(day, -1, GetDate())
 AND CONVERT(DATE,Rx.FillDate,112) =  '${fromDate}'
ORDER BY
  FillDate DESC
      `

  console.log(queryStr)

  return this.query(queryStr, attributes).then(handleQueryResolve)
}

// SELECT
// Pat.FirstName,
// Pat.LastName,
// Pat.FirstName + ' ' + Pat.LastName AS PatientName,
// rx.ID,
// MixID,
// rx.DocID,
// Rx.PatID as PatientID,
// DrgMix.Description,
// OrigRxNum,
// RxNum,
// --FillDate,
// CONVERT(DATE,Rx.FillDate,112) AS FillDate,
// DispQty,
// AAC,
// Cost,
// Markup,
// Fee,
// MixTime,
// MixFee,
// Status,
// doctor.FirstName as DoctorFirstName,
// doctor.LastName as DoctorLastName
// --'Pending' as mixStatus
// FROM
// pharmacy.dbo.Rx
// INNER JOIN pharmacy.dbo.Pat on rx.PatID = pat.ID
// INNER JOIN pharmacy.dbo.DrgMix ON DrgMix.ID = rx.MixID
// INNER JOIN pharmacy.dbo.Doc as doctor ON doctor.ID = rx.DocID
// WHERE
// MixID >= 1
// -- AND Rx.FillDate > DATEADD(day, -1, GetDate())
// AND CONVERT(DATE,Rx.FillDate,112) =  '${fromDate}'
// ORDER BY
// FillDate DESC
